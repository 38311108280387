/*
 * @Date: 2022-01-17 14:20:47
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-07 11:53:10
 * @FilePath: \front-public\packages\work-platform\src\components\shared\CForm\Form.typings.ts
 */
export enum BusEvents {
  AddFields = 'addFields',
  RemoveFields = 'removeFields',
  BlurFields = 'blurFields',
  ChangeFields = 'changeFields',
}

export interface Rule {
  message: string;
  required?: boolean;
  trigger?: string;
  validator?: Function;
  type?: string;
  max?: number;
  min?: number;
}

export interface ValidateInfo {
  isError: boolean;
  message: string;
}
