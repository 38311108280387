/**
 * 集成操作树的工具类
 */

interface Tree<T> {
  children: T[];
  pos?: string;
}

export default class TreeUtils {
  /**
   * 通过指定的key获取树节点信息
   * @param value // 键值
   * @param key   // 键名
   * @param treeArr   // 树数组
   * @return 返回树节点信息
   */
  static getTreeNodeStateByKey<T extends Tree<T>> (
    value: string,
    key: string,
    treeArr: T[],
  ): {nodeState: T; nodePos: string} {
    let isStop = false;
    const nodePos: number[] = [];
    let nodeState!: T;

    const findTreeNodeState = function (val: string, k: string, array: T[]) {
      for (let i: number = 0, len: number = array.length; i < len; i++) {
        nodePos.push(i);
        const item = array[i];

        if (val === item[k]) {
          isStop = true;
          nodeState = item;
          return;
        }

        const children: T[] = item.children;
        if (children && children.length) {
          findTreeNodeState(val, k, children);
        }

        if (isStop) return;

        nodePos.pop();
      }
    };

    findTreeNodeState(value, key, treeArr);

    return { nodeState, nodePos: `0-${nodePos.join('-')}` };
  }

  /**
   * 遍历树
   * @param tree
   * @param callback
   */
  static forEachTree<T extends Tree<T>> (tree: T[], callback: (item: T) => void): void {
    const forEachTree = (t: T[], c: any) => {
      for (let i = 0, len = t.length; i < len; i++) {
        const item: T = t[i];

        c(item);

        if (item.children && item.children.length) {
          forEachTree(item.children, callback);
        }
      }
    };

    forEachTree(tree, callback);
  }

  /**
   * 通过位置来获取树节点信息
   * @param treeArr 树数组
   * @param nodePos 位置
   * @return 返回树节点信息
   */
  static getTreeNodeStateByPos<T extends Tree<T>> (treeArr: T[], nodePos: string): T {
    const pos: string[] = nodePos?.split('-').slice(1);
    let resultNode: any = {};
    let tree: any = treeArr;

    for (let i = 0, len = pos.length; i < len; i++) {
      resultNode = tree[Number(pos[i])];

      if (i < len) {
        tree = resultNode?.children;
      }
    }

    return resultNode;
  }

  /**
   * 根据位置获取dom元素
   */
  static getItemHtmlByPos (pos: string, treeDom: HTMLElement) {
    const className: string = `.node-${pos}`;
    const dom: HTMLElement = treeDom.querySelector(className) as HTMLElement;

    return dom;
  }

  /**
   * 获取指定标签名的第一个父节点
   * @param dom
   * @param target
   */
  static getParentItemHtml (dom: HTMLElement, target: string) {
    let htmlDom: HTMLElement = dom;

    while (true) {
      const tagName: string = htmlDom.tagName.toLowerCase();
      const targetName: string = target.toLowerCase();
      if (tagName === targetName) {
        break;
      } else {
        htmlDom = htmlDom.parentElement!;
      }
    }

    return htmlDom;
  }

  /**
   * 获取dom元素距离滚动条元素的位置
   */
  static getItemOffsetTop (itemHtml: HTMLElement, treeDom: HTMLElement) {
    let dom: any = itemHtml;
    let offsetTop: number = 0;

    while (dom) {
      if (treeDom.className === dom.className) {
        break;
      } else {
        offsetTop += dom.offsetTop;
        dom = dom.offsetParent;
      }
    }

    return offsetTop;
  }
}
