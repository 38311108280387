





































import {
  Component, Vue, Prop, Watch, Ref, Emit,
} from 'vue-property-decorator';
import { Checkbox } from '@h3/antd-vue';

import { FlowCenterService } from '@WorkPlatform/services';

@Component({
  name: 'SetField',
  components: {
    ACheckbox: Checkbox,
  },
})
export default class SetField extends Vue {
  @Prop() value!: boolean;

  @Prop({ default: () => [] }) columns!: any;

  @Prop({ default: () => [] }) hiddenField!: any;

  @Prop({ default: () => [] }) defaultHideFieldList!: any;

  @Ref() cform!: any;

  visible: boolean = false;

  submiting: boolean = false;

  fieldColumns: any[] = [];

  get filterColumns () {
    const columns: any[] = this.columns.filter(
      (item: any) => item.key !== 'index',
    );
    const disabledSetFieldColumns: any[] = columns.filter(
      (item: any) => item.disabledSetField,
    );
    const enabledSetFieldColumns: any[] = columns.filter(
      (item: any) => !item.disabledSetField,
    );

    return [...enabledSetFieldColumns, ...disabledSetFieldColumns];
  }

  get checkedAll () {
    return this.fieldColumns.every((item: any) => {
      return item.checked;
    });
  }

  get indeterminate () {
    let res: boolean = false;

    if (this.fieldColumns.length) {
      if (!this.checkedAll) {
        res = this.fieldColumns.some((item: any) => item.checked);
      }
    }

    return res;
  }

  setColumns () {
    return this.filterColumns.map((item: any) => {
      const hiddenField: string[] = this.hiddenField.length
        ? this.hiddenField
        : this.defaultHideFieldList;

      if (!hiddenField.includes(item.key)) {
        this.$set(item, 'checked', true);
      } else {
        this.$set(item, 'checked', false);
      }

      return item;
    });
  }

  get todoType () {
    return Number(this.$route.query.todoType) || 1;
  }

  checkAll (e: any) {
    this.fieldColumns.forEach((item: any) => {
      if (!item.disabledSetField) {
        item.checked = e.target.checked;
      }
    });
  }

  @Emit('reload')
  reload () {}

  async comfirm () {
    this.submiting = true;
    const hideFields: string[] = this.fieldColumns
      .filter((item: any) => {
        return !item.checked;
      })
      .map((item: any) => {
        return item.key;
      });

    if (!hideFields.length) {
      hideFields.push('noHide');
    }
    const res: any = await FlowCenterService.saveHideField({
      hideFields: hideFields,
      todoType: this.todoType,
    });

    if (res.success) {
      this.$message.success('保存成功');
      this.cancel();
      this.reload();
    }

    this.submiting = false;
  }

  cancel () {
    this.$emit('input', false);
  }

  @Watch('value')
  onChangeValue (v: boolean) {
    this.visible = v;

    if (v) {
      this.fieldColumns = this.setColumns();
    }
  }
}
