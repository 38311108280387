






































































































import {
  Component, Vue, Ref, Prop, Watch,
} from 'vue-property-decorator';
import { Button, Checkbox } from '@h3/antd-vue';
import { FlowService } from '@WorkPlatform/services';
import CreateEntrust from './create-entrust.vue';
import { CTable, CardInformation } from '@cloudpivot7-business-components/pc';
import { tableColumn } from '@WorkPlatform/types/type';

@Component({
  name: 'FlowEntrustComponent',
  components: {
    CTable,
    CreateEntrust,
    AButton: Button,
    ACheckbox: Checkbox,
    CardInformation,
  },
})
export default class FlowEntrustComponent extends Vue {
  @Ref() CTable!: any;

  @Prop({ default: false }) isFlowCenter!: boolean;

  isShowCreateFlowEntrust: boolean = false;

  operateType: number = 1; // 1: 新增 2：编辑

  editData: any = {};

  checkTableData: any[] = [];

  searchParams: any = {};

  isFilterExpired: boolean = false;

  /**
   * table列定义
   */
  tableColumns: tableColumn[] = [
    {
      width: 60,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      slots: { title: 'indexTitle', name: '序号' },
      scopedSlots: { customRender: 'index' },
    },
    {
      dataIndex: 'consignorName',
      key: 'consignorName',
      ellipsis: true,
      slots: { title: 'consignorNameTitle', name: '委托人' },
    },
    {
      dataIndex: 'mandataryName',
      key: 'mandataryName',
      ellipsis: true,
      slots: { title: 'mandataryNameTitle', name: '被委托人' },
      scopedSlots: { customRender: 'mandataryName' },
    },
    {
      dataIndex: 'entrustTime',
      key: 'entrustTime',
      ellipsis: true,
      slots: { title: 'entrustTimeTitle', name: '受托期限' },
      scopedSlots: { customRender: 'entrustTime' },
    },
    {
      width: 100,
      dataIndex: 'action',
      key: 'action',
      slots: { title: 'actionTitle', name: '操作' },
      scopedSlots: { customRender: 'action' },
    },
  ];

  @Watch('isFlowCenter', { immediate: true })
  onFlowCenterChange () {
    if (this.isFlowCenter) {
      this.tableColumns.splice(1, 1);
    }
  }

  get isSuperAdmin () {
    const res: boolean = JSON.parse(localStorage.getItem('userInfo') || '{}').username === 'admin';
    return res;
  }

  get checkedTableList () {
    return !this.checkTableData.length;
  }

  showCreateEntrust () {
    this.isShowCreateFlowEntrust = true;
    this.operateType = 1;
  }

  changeTable () {
    if (this.CTable) {
      this.checkTableData = this.CTable.getSelectionRow();
    }
  }

  reload () {
    this.search();
  }

  /**
   * 搜索
   */
  search (searchParams: any = {}) {
    this.searchParams = searchParams;
    this.CTable.pagination.current = 1;
    this.CTable.pagination.pageSize = 20;
    this.CTable.getTableList();
  }

  filterExpired () {
    this.search();
  }

  /**
   * 获取列表数据接口
   */
  async getList (params?: any) {
    const p: any = {
      ...params,
      ...this.searchParams,
      page: params.page,
      isFilterExpired: this.isFilterExpired,
    };

    if (this.isFlowCenter) {
      p.isCurrentUser = true;
    }

    const res: any = await FlowService.getFlowEntrustList(p);

    if (res.success) {
      const data: any = res.data || [];

      data.forEach((item: any) => {
        item.entrustTime = `${item.entrustStartTime}~${item.entrustEndTime}`;
        let entrustTypeName: any = '';
        if (item.entrustType === 1) {
          entrustTypeName = '流程审批';
        } else if (item.entrustType === 2) {
          entrustTypeName = '流程发起';
        }
        item.entrustTypeName = entrustTypeName;
      });
    }

    return res;
  }

  editHandler (record: any) {
    this.editData = record;
    this.isShowCreateFlowEntrust = true;
    this.operateType = 2;
  }

  delHandler (record: any) {
    const okOk = () => {
      const params = [record.id];

      FlowService.delEntrust(params).then(() => {
        this.$message.success('删除成功！');
        this.reload();
      });
    };

    this.$confirm({
      title: '确定要删除这条流程委托吗？',
      content: '删除后，该数据将被全部删除且无法恢复，确认删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: okOk,
    });
  }

  delBath () {
    const okOk = () => {
      const params = this.checkTableData.map((item: any) => {
        return item.id;
      });

      FlowService.delEntrust(params).then(() => {
        this.$message.success('删除成功！');
        this.reload();
      });
    };

    this.$confirm({
      title: '确定要删除已选中信息吗？',
      content: '删除后，数据将被全部删除且无法恢复，确认删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: okOk,
    });
  }
}
