var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flow-body-wrapper"},[_c('div',{staticClass:"flow-manager"},[_c('div',{staticClass:"flow-header"},[_c('div',{staticClass:"header-wrapper"},[(!_vm.isFlowCenter || (_vm.isFlowCenter && !_vm.isSuperAdmin))?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.showCreateEntrust}},[_vm._v(" 新建 ")]):_vm._e(),_c('a-button',{attrs:{"disabled":_vm.checkedTableList},on:{"click":_vm.delBath}},[_vm._v(" 批量删除 ")]),_c('div',{staticClass:"expired-checkbox"},[_c('a-checkbox',{on:{"change":_vm.filterExpired},model:{value:(_vm.isFilterExpired),callback:function ($$v) {_vm.isFilterExpired=$$v},expression:"isFilterExpired"}},[_vm._v(" 只显示未过期的流程委托 ")])],1)],1)]),_c('div',{staticClass:"wrapper-table"},[_c('CTable',{ref:"CTable",attrs:{"tableColumns":_vm.tableColumns,"enableCheckbox":true,"getList":_vm.getList},on:{"change":_vm.changeTable},scopedSlots:_vm._u([{key:"mandataryName",fn:function(ref){
var record = ref.record;
return [_c('CardInformation',{attrs:{"trigger":"click","placement":"bottomLeft","cardParams":{
              type: 'user',
              id: record.mandataryId,
            }}},[_c('div',{staticClass:"user",attrs:{"slot":"card-content"},on:{"click":function($event){$event.stopPropagation();}},slot:"card-content"},[_c('div',{staticClass:"user-icon"},[_c('span',[_vm._v(_vm._s(record.mandataryName.split('')[0]))])]),_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(record.mandataryName)+" ")])])])]}},{key:"entrustTime",fn:function(ref){
            var record = ref.record;
return [_c('span',{class:{'deleted-status-span': record.expired},attrs:{"title":record.entrustTime}},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(record.entrustTime))]),(record.expired)?_c('span',{staticClass:"deleted-status"},[_vm._v("已过期")]):_vm._e()])]}},{key:"action",fn:function(ref){
            var record = ref.record;
return [_c('a-button',{staticClass:"operate-btn",attrs:{"type":"link","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.editHandler(record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{staticClass:"operate-btn",attrs:{"type":"link","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.delHandler(record)}}},[_vm._v(" 删除 ")])]}}])})],1)]),_c('CreateEntrust',{attrs:{"operateType":_vm.operateType,"editData":_vm.editData,"isFlowCenter":_vm.isFlowCenter},on:{"reload":_vm.reload},model:{value:(_vm.isShowCreateFlowEntrust),callback:function ($$v) {_vm.isShowCreateFlowEntrust=$$v},expression:"isShowCreateFlowEntrust"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }