export class CommonServices {
  /**
   * 将免登信息设置在url上
   * @param query
   * @returns
   */
  static setLoginParam (query: any) {
    const urlQuery = sessionStorage.getItem('urlQuery');
    if (urlQuery) {
      try {
        const tempUrlQuery = JSON.parse(urlQuery);
        if (tempUrlQuery.agentId && tempUrlQuery.corpId) {
          query.agentId = tempUrlQuery.agentId;
          query.corpId = tempUrlQuery.corpId;
        }
      } catch (err) {
        console.error(err);
      }
    }
    return query;
  }
}
