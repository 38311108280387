







































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import validateForm from './validate';
// eslint-disable-next-line
import { BusEvents, Rule, ValidateInfo } from './Form.typings';
import Utils from './Utils';

import Bus from './bus';

// 允许鼠标移开立即验证的输入域
const BLUR_FIELDS = ['a-input', 'a-auto-complete', 'a-input-number', 'a-mentions'];

@Component({
  name: 'cFormItem',
})
export default class FormItem extends Vue {
  @Prop() label!: string;

  @Prop() prop!: string;

  @Prop() error!: string;

  @Prop() rules!: Rule[];

  isError: boolean = false;

  errmsg: string = '';

  initialValue: any = '';

  // 表单字段是否内联
  get inline () {
    return this.form.inline;
  }

  // label的宽度
  get labelWidth () {
    return this.form.labelWidth;
  }

  // label的位置
  get labelPosition () {
    return this.form.labelPosition;
  }

  // 必填的位置
  get requiredPosition () {
    return this.form.requiredPosition;
  }

  // label是否是在表单域顶部
  get isPositionTop () {
    let res: boolean = false;

    if (this.labelPosition === 'top') {
      res = true;
    }

    return res;
  }

  // 表单域是否必填
  get required () {
    let res: boolean = false;

    if (!this.prop) return res;

    const rules: any = this.getRules();

    res = rules.some(rule => rule.required);

    return res;
  }

  // Form组件
  get form () {
    let parent: any = this.$parent;
    let parentName: string = parent.$options._componentTag;
    while (parentName !== 'c-form') {
      parent = parent.$parent;
      parentName = parent.$options._componentTag;
    }
    return parent;
  }

  // 当前字段的值
  get fieldValue () {
    const model: any = this.form.model;
    const prop: string = this.prop;

    if (!model || !prop) {
      return false;
    }

    return Utils.getPropDataByProp('model', model, prop, true).value;
  }

  @Watch('prop')
  onPropChange (prop: string) {
    if (prop) return;
    this.clearValidate();
  }

  @Watch('error')
  onErrorChange (value: string) {
    if (!this.prop) return;

    this.errmsg = value;
    this.isError = !!value;
  }

  /**
   * 获取当前字段的验证规则
   */
  getRules () {
    const orgFormRules: any = this.form.rules || [];
    const selfRules: any = this.rules || [];
    let formRules: any = [];

    if (!this.prop) return [];

    const propData: any = Utils.getPropDataByProp('rules', orgFormRules, this.prop || '');

    formRules = propData.value;

    return [].concat(formRules, selfRules || []);
  }

  /**
   * 获取当前字段带触发器的规则
   */
  getFilteredRule (trigger: string) {
    if (!this.prop) return [];

    const rules: Rule[] = this.getRules();

    return rules
      .filter(rule => {
        if (
          (rule.trigger && trigger === 'blur') ||
          (rule.trigger && trigger === 'change') ||
          trigger === 'click'
        ) { return true; }

        if (Array.isArray(rule.trigger)) {
          return rule.trigger.indexOf(trigger) > -1;
        } else {
          return rule.trigger === trigger;
        }
      })
      .map(rule => Object.assign({}, rule));
  }

  /**
   * 验证当前字段
   */
  validate (trigger: string = 'click') {
    if (!this.prop) return false;

    const rules: Rule[] = this.getFilteredRule(trigger);
    const fieldValue: any = this.fieldValue;

    if (!rules || rules.length === 0) return false;

    if (!this.required) {
      if ((Array.isArray(fieldValue) && fieldValue.length === 0) ||
        (typeof fieldValue === 'string' && fieldValue === '') ||
        (typeof fieldValue === 'object' && fieldValue === null) ||
        (typeof fieldValue === 'undefined' && fieldValue === undefined)
      ) {
        this.errmsg = '';
        this.isError = false;
        return false;
      }
    }

    for (const rule of rules) {
      const validateInfo: ValidateInfo = validateForm.startValidate(fieldValue, rule);
      this.isError = validateInfo.isError;

      if (this.isError) {
        this.errmsg = validateInfo.message;
        break;
      } else {
        this.errmsg = '';
      }
    }

    return this.isError;
  }

  /**
   * 清空验证规则
   */
  clearValidate () {
    this.isError = false;
    this.errmsg = '';
  }

  /**
   * 重置字段值
   */
  resetField () {
    this.isError = false;
    this.errmsg = '';

    const model: any = this.form.model;
    const value: any = this.fieldValue;
    const path: string = this.prop;

    const prop = Utils.getPropDataByProp('model', model, path);

    if (Array.isArray(value)) {
      prop.parentData[prop.key] = [].concat(this.initialValue);
    } else {
      prop.parentData[prop.key] = this.initialValue;
    }

    this.$nextTick(() => {
      this.clearValidate();
    });
  }

  /**
   * 当前字段绑定鼠标离开时的验证
   */
  // eslint-disable-next-line
  onFieldBlur (callback: any) {
    this.validate('blur');
  }

  /**
   * 当前字段绑定当前值改变时的验证
   */
  onFieldChange () {
    this.validate('change');
  }

  /**
   * 添加验证事件
   */
  addValidateEvents () {
    if (!this.prop) return;
    const rules: Rule[] = this.getRules();

    if (rules.length || this.required !== undefined) {
      const vNodes: any = this.$slots.default;
      const vNode: any = vNodes[0];

      if (!vNode) return;

      const componentInstance: any = vNode.componentInstance;
      const componentOptions: any = vNode.componentOptions;

      if (!componentInstance) return;
      if (!componentOptions) return;

      const tag: string = componentOptions.tag;
      let el: any = componentInstance.$el;

      if (tag === 'a-input-number' && el) {
        el = el.querySelector('input');
      }

      if (BLUR_FIELDS.includes(tag)) {
        const isBlurRule: boolean = rules.some(item => item.trigger === 'blur');

        if (!isBlurRule) return;

        el.removeEventListener('blur', () => {
        });
        el.addEventListener('blur', this.onFieldBlur);
      }

      const isChangeRule: boolean = rules.some(item => item.trigger === 'change');

      if (!isChangeRule) return;

      this.$watch('fieldValue', (newValue: any, oldeValue: any) => {
        if (newValue === oldeValue) return;

        this.onFieldChange();
      });
    }
  }

  /**
   * 移除验证事件
   */
  removeValidateEvents () {
    this.$off();
  }

  initFormItem () {
    if (this.prop) {
      Bus.$emit(BusEvents.AddFields, this);

      let initialValue: any = this.fieldValue;
      if (Array.isArray(initialValue)) {
        if (!initialValue.length) initialValue = [];

        initialValue = [].concat(initialValue || []);
      }

      this.initialValue = initialValue;

      this.addValidateEvents();
    }
  }

  mounted () {
    this.initFormItem();
  }

  destroyed () {
    if (this.prop) {
      Bus.$emit(BusEvents.RemoveFields, this);
    }
  }
}
