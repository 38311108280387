








































import {
  Component, Prop, PropSync, Vue, Watch, Ref,
} from 'vue-property-decorator';
import { Modal, Button, Table } from '@h3/antd-vue';
import { tableColumn } from '@WorkPlatform/types/type';

@Component({
  name: 'agreeFlowFail',
  components: {
    AModal: Modal,
    ATable: Table,
    AButton: Button,
  },
})
export default class agreeFlowFail extends Vue {
  @PropSync('isVisible', { default: false }) visible!:boolean;

  @Ref() Approve!: any;

  @Prop({ default: () => ({}) }) failInfo!:any;

  isLoading: boolean = false;

  initTableData: any = [];

  tableColumns: tableColumn[] = [
    {
      dataIndex: 'instanceName',
      key: 'instanceName',
      title: '流程名称',
      width: 220,
      slots: { title: 'instanceName', name: '流程名称' },
      scopedSlots: { customRender: 'instanceName' },
    },
    {
      dataIndex: 'originatorName',
      key: 'originatorName',
      title: '发起人',
      width: 130,
      disabledSetField: true,
      ellipsis: true,
      slots: { title: 'originatorName', name: '发起人' },
    },
    {
      dataIndex: 'reason',
      key: 'reason',
      title: '失败原因',
      ellipsis: true,
      slots: { title: 'reason', name: '失败原因' },
    },
  ];

  @Watch('failInfo', { immediate: true })
  visibleChange (v) {
    if (Object.keys(v).length) {
      this.initTableData = v.failResults;
    }
  }

  handleCancel () {
    this.$emit('update:isVisible', false);
    this.$emit('reload');
  }
}
