/*
 * @Date: 2022-06-07 18:07:52
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 12:07:02
 * @FilePath: \front-public\packages\work-platform\src\components\shared\CForm\Utils.ts
 */
export default class Utils {
  /**
   * 获取prop获取数据
   * @param data
   * @param prop
   */
  // eslint-disable-next-line
  static getPropDataByProp (dataName: string, data: any, prop: string, strict: boolean = false) {
    const propItems: any[] = prop.split('.');
    const length: number = propItems.length;
    const errorPath: any = [];
    let resData: any = data;
    let resProp: string = '';
    let parentData: any = data;

    for (const [index, propItem] of propItems.entries()) {
      errorPath.push(propItem);

      if (propItem in resData) {
        parentData = resData;

        resData = resData[propItem];
        resProp = propItem;

        if (index < length - 1) {
          // 除了最后一个不需要验证是否是复合类型，其它都要验证
          // 如果不是对象和数组类型，都抛出错误
          if (
            (
              !(resData instanceof Object) &&
            !(resData instanceof Array)
            ) ||
            resData === null
          ) {
            throw new Error(`[CForm Error][Form] property of ${errorPath.join('.')} is not object or array`);
          }
        }
      } else {
        parentData = {};
        resData = [];
        resProp = propItem;

        break;
      }
    }

    return {
      parentData: parentData,
      key: resProp,
      value: resData,
    };
  }

  /**
   * 深拷贝数据
   */
  static deepCopy (data: any) {
    const result: any = Array.isArray(data) ? [] : {};

    for (const key in data) {
      // eslint-disable-next-line
      if (!data.hasOwnProperty(key)) continue;

      if (typeof data[key] === 'object' && data[key] !== null) {
        result[key] = this.deepCopy(data[key]);
      } else {
        result[key] = data[key];
      }
    }

    return result;
  }
}
