





import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import Bus from './bus';
// eslint-disable-next-line
import { BusEvents, Rule } from './Form.typings';

@Component({
  name: 'cForm',
})
export default class Form extends Vue {
  @Prop() model!: any;

  @Prop() rules!: Rule;

  // 内联表单
  @Prop({ default: false }) inline!: boolean;

  // 表单名称位置
  @Prop({ default: 'left' }) labelPosition!: string;

  // 必填符号位置
  @Prop({ default: 'left' }) requiredPosition!: string;

  // 表单名称宽度
  @Prop({ default: '80px' }) labelWidth!: string;

  fields: any = [];

  @Watch('rules')
  onRulesChange () {
    this.fields.forEach((field: any) => {
      field.removeValidateEvents();
      field.addValidateEvents();
    });
  }

  created () {
    Bus.$off();
    Bus.$on(BusEvents.AddFields, this.addFields);

    Bus.$on(BusEvents.RemoveFields, this.removeFields);
  }

  /**
   * 添加FormItem组件
   */
  addFields (field: any) {
    if (field.prop) {
      this.fields.push(field);
    }
  }

  /**
   * 移除FormItem组件
   * */
  removeFields (field: any) {
    if (field.prop) {
      this.fields.splice(this.fields.indexOf(field), 1);
    }
  }

  /**
   * 验证当前字段
   */
  validate () {
    if (!this.model) {
      console.warn('[Element Warn][Form]model is required for validate to work!');
      return false;
    }

    const isValid: boolean[] = [];

    this.fields.forEach((comp: any) => {
      if (comp.prop) {
        const res: boolean = comp.validate();

        isValid.push(res);
      }
    });

    return isValid.every((item: boolean) => !item);
  }

  /**
   * 清空验证规则
   */
  clearValidate (props: any = []) {
    let fields = [];
    const propsLength: number = props.length || 0;
    if (propsLength) {
      fields = typeof props === 'string'
        ? this.fields.filter((field: any) => props === field.prop)
        : this.fields.filter((field: any) => props.indexOf(field.prop) > -1);
    } else {
      fields = this.fields;
    }

    fields.forEach((field: any) => {
      field.clearValidate();
    });
  }

  /**
   * 重置字段值
   */
  resetFields (props: any = []) {
    if (!this.model) {
      console.warn('[Element Warn][Form]model is required for resetFields to work.');
      return;
    }

    let fields = [];
    const propsLength: number = props.length || 0;
    if (propsLength) {
      fields = typeof props === 'string'
        ? this.fields.filter((field: any) => props === field.prop)
        : this.fields.filter((field: any) => props.indexOf(field.prop) > -1);
    } else {
      fields = this.fields;
    }

    fields.forEach((field: any) => {
      field.resetField();
    });
  }

  /**
   * 验证指定字段的规则
   */
  validateField (props: any) {
    let fields = [];
    const propsLength: number = props.length || 0;
    if (propsLength) {
      fields = typeof props === 'string'
        ? this.fields.filter((field: any) => props === field.prop)
        : this.fields.filter((field: any) => props.indexOf(field.prop) > -1);
    } else {
      fields = this.fields;
    }

    if (!fields.length) {
      console.warn('[Element Warn]please pass correct props!');
      return false;
    }

    fields.forEach((field: any) => {
      field.validate('');
    });
  }
}
