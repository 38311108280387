






























































































































































































































































































import {
  Component, Vue, Ref, Watch,
} from 'vue-property-decorator';
import moment from 'moment';
import { Form, FormItem } from '@WorkPlatform/components/shared/CForm/index';

import {
  Select, Icon, Button, Tooltip, DatePicker, Input,
} from '@h3/antd-vue';

import SetField from './set-field.vue';
import { CTable, CardInformation } from '@cloudpivot7-business-components/pc';
import { FlowCenterService, WorkPlatformService } from '@WorkPlatform/services';
import agreeFlow from './comp/agree-flow.vue';
import agreeFlowFail from './comp/agree-flow-fail.vue';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { isPhone, getImageUrl } from '@WorkPlatform/common/utils';
import { CommonServices } from '@WorkPlatform/common/service';

@Component({
  name: 'FlowInitiateComponent',
  components: {
    ASelect: Select,
    ASelectOption: Select.Option,
    AIcon: Icon,
    AButton: Button,
    ATooltip: Tooltip,
    AInput: Input,
    ARangePicker: DatePicker.RangePicker,
    CTable,
    CForm: Form,
    CFormItem: FormItem,
    // StaffSelector,
    SetField,
    agreeFlow,
    agreeFlowFail,
    CardInformation,
  },
})
export default class FlowInitiateComponent extends Vue {
  @Ref() wrapperTable!: any;

  @Ref() cTable!: any;

  todoTypeOption: any[] = [
    { name: '待办', value: 1 },
    { name: '待阅', value: 2 },
    { name: '已办', value: 3 },
    { name: '已阅', value: 4 },
  ];

  flowStatus: any[] = [
    { label: '进行中', value: 1, enName: 'PROCESSING' },
    { label: '已完成', value: 2, enName: 'COMPLETED' },
    { label: '已作废', value: 3, enName: 'CANCELED' },
    { label: '流程异常', value: 4, enName: 'EXCEPTION' },
  ];

  form: any = {
    instanceName: '',
    moduleCode: undefined,
    originator: [],
    appCode: '', // 应用编码
    batchOperate: false, // 是否支持批量处理
    publishTime: [],
    state: undefined,
    handlerPerson: [],
  };

  // 待办应用列表
  appList: any = [];

  // 显示待办弹窗
  showAgreeFlow: boolean = false;

  // 批量审批部分失败
  showAgreeFlowFail: boolean = false;

  failInfo: any = {};

  flowTemplate: any[] = [];

  isExpand: boolean = false;

  isShowSetField: boolean = false;

  tableList: any[] = [];

  /**
   * table列定义
   */
  columns: any[] = [
    {
      fixed: 'left',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      align: 'center',
      disabledSetField: true,
      slots: { title: 'index', name: '序号' },
      scopedSlots: { customRender: 'index' },
    },
    {
      dataIndex: 'instanceName',
      key: 'instanceName',
      width: 250,
      slots: { title: 'instanceName', name: '流程名称' },
      scopedSlots: { customRender: 'instanceName' },
    },
    {
      dataIndex: 'workflowName',
      key: 'workflowName',
      width: 250,
      disabledSetField: true,
      ellipsis: true,
      slots: { title: 'workflowName', name: '流程模板' },
    },
    {
      dataIndex: 'activityName',
      key: 'activityName',
      ellipsis: true,
      width: 172,
      slots: { title: 'activityName', name: '当前节点' },
    },
    {
      dataIndex: 'participantNames',
      key: 'participantNames',
      ellipsis: true,
      width: 100,
      slots: { title: 'participantNames', name: '处理人' },
    },
    {
      dataIndex: 'usedTime',
      key: 'usedTime',
      ellipsis: true,
      width: 172,
      slots: { title: 'usedTime', name: '停留时间' },
      scopedSlots: { customRender: 'usedTime' },
    },
    {
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
      width: 120,
      slots: { title: 'state', name: '流程状态' },
      scopedSlots: { customRender: 'state' },
    },
    {
      dataIndex: 'originatorName',
      key: 'originatorName',
      ellipsis: true,
      disabledSetField: true,
      width: 100,
      slots: { title: 'originatorName', name: '发起人' },
      scopedSlots: { customRender: 'originatorName' },
    },
    {
      dataIndex: 'createdTime',
      key: 'createdTime',
      ellipsis: true,
      width: 182,
      slots: { title: 'createdTime', name: '发起时间' },
    },
    {
      dataIndex: 'departmentPathName',
      key: 'departmentPathName',
      ellipsis: true,
      disabledSetField: true,
      slots: { title: 'departmentPathName', name: '发起组织' },
      scopedSlots: { customRender: 'departmentPathName' },
    },
  ];

  hideFieldList: any[] = [];

  fieldColumns: any[] = [];

  defaultHideFieldList: any[] = ['participantNames', 'baomidengji'];

  checkData: any[] = [];

  get wrapperTableHeight () {
    return this.wrapperTable.offsetHeight;
  }

  get tableColumns () {
    return JSON.parse(JSON.stringify(this.columns));
  }

  get isShowFlowStatausFormItem () {
    return this.todoType === 3;
  }

  get todoType () {
    return Number(this.$route.query.todoType) || 1;
  }

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  @Watch('todoType')
  async onTodoTypeChange () {
    this.getFlowTemplate();
    this.setColumn();
    await this.search();
  }

  created () {
    this.getFlowTemplate();
    this.setColumn();
    window.addEventListener('message', this.reloadPage, false);
    this.getTodoApplist();
  }

  switchSearch () {
    this.form.batchOperate = !this.form.batchOperate;
    this.reload();
  }

  agreeFlow () {
    this.showAgreeFlow = true;
  }

  agreeAllFlow () {
    this.cTable.onChangeCheckAll({ target: { checked: true } });
    this.showAgreeFlow = true;
  }

  hasFail (failInfo) {
    this.showAgreeFlowFail = true;
    this.failInfo = failInfo;
  }

  changeTable () {
    if (!this.cTable) return;

    const selectionRow: any[] = this.cTable.getSelectionRow();

    const tempData: any = selectionRow.map(item => {
      return item.id;
    });
    this.checkData = [...tempData];
  }

  hasCheck (val) {
    if (val) {
      const tempData: any = val.map(item => {
        return item.id;
      });
      this.checkData = [...tempData];
    }
  }

  expandSearch () {
    this.isExpand = !this.isExpand;
    const wrapperTableHeight: number = this.wrapperTable.offsetHeight;
    // 待办批量处理
    if (this.todoType === 1 && this.form.batchOperate) {
      this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
        }px`;
      this.cTable.setScrollHeight();
    }

    if (this.todoType !== 3) return;

    this.wrapperTable.style.height = `${this.isExpand ? wrapperTableHeight - 71 : wrapperTableHeight + 71
      }px`;
    this.cTable.setScrollHeight();
  }

  deepCopy (value: any) {
    return JSON.parse(JSON.stringify(value));
  }

  async showSetField () {
    this.isShowSetField = true;
  }

  async reload () {
    await this.getTodoApplist();
    await this.resetSearch();
    await this.setColumn();
    this.checkData = [];
    this.search();
  }

  onSearch () {
    const form: any = this.form;

    // 发起人
    const originator: any = form.originator[0] || {};

    // 当前处理人
    // 发起时间
    const publishTime: any = form.publishTime || [];
    let startMomentTime: string = '';
    let endMomentTime: string = '';

    if (publishTime.length) {
      const startTime: any = publishTime[0] || {};
      const endTime: any = publishTime[1] || {};

      startMomentTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
      endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
    }

    const params: any = {
      instanceName: form.instanceName,
      moduleCode: form.moduleCode,
      startTime: startMomentTime,
      endTime: endMomentTime,
      instanceState: form.state,
      originator: originator.id,
      batchOperate: form.batchOperate,
    };

    this.search(params);
  }

  resetSearch () {
    const { batchOperate } = this.form;
    this.form = {
      instanceName: '',
      moduleCode: undefined,
      originator: [],
      publishTime: [],
      state: undefined,
      handlerPerson: [],
      batchOperate,
    };
  }

  /**
   * 获取流程模板
   */
  getFlowTemplate () {
    FlowCenterService.getFlowTemplate({ todoType: 0 }).then(
      (res: any) => {
        if (res.success) {
          this.flowTemplate = res.data || [];
        } else {
          this.flowTemplate = [];
        }
      },
    );
  }

  async updateFlowData () {
    this.checkData = [];
    await this.resetSearch();
    await this.onSearch();
  }

  async toggleMenu (todoType: any) {
    if (todoType === this.todoType) return;

    this.isExpand = false;
    this.wrapperTable.style.height = this.wrapperTableHeight + 'px';
    this.resetSearch();
    this.$router.push({
      name: 'flow-center',
      query: {
        todoType: todoType,
      },
    });
  }

  async getHideField () {
    this.hideFieldList = [];
  }

  async setColumn () {
    await this.getHideField();
    this.fieldColumns = [];

    for (const item of this.tableColumns) {
      if (!this.hideFieldList.includes(item.key)) {
        this.fieldColumns.push(item);
      }
    }
  }

  goPage (item: any) {
    const params: any = {
      appCode: item.appCode,
      appVersion: item.appVersion,
    };
    const winOpen: any = !this.isFreeLoginPlatform && window.open('', '_blank');

    WorkPlatformService.getAppUrl(params).then((res: any) => {
      if (res.success) {
        const pageArr =
          typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];

        let pageJson: any;
        pageArr.forEach((page: any) => {
          if (page.pageType === 'PC') {
            pageJson = page;
          }
        });

        if (!pageJson) {
          pageArr.forEach((page: any) => {
            if (page.pageType === 'ALL') {
              pageJson = page;
            }
          });
        }

        let query: any = {
          groupCode: '',
          pageGroupCode: '',
          pageCode: '',
          workItemId: '',
          workflowInstanceId: item.id,
          appVersion: item.appVersion,
          workflowCode: item.workflowCode,
          isFromTask: true,
          ...pageJson,
        };
        query = CommonServices.setLoginParam(query);
        const str: string = Object.entries(query)
          .map((queryArr: any[]) => {
            return queryArr.join('=');
          })
          .join('&');

        let url = `${res.data}?${str}`;

        if (process.env.NODE_ENV !== 'production') {
          url = `http://localhost${res.data.substring(
            res.data.indexOf('/app_launcher/'),
          )}?${str}`;
        }

        try {
          const token = localStorage.getItem('token');
          const jumpTime: number = 100;
          if (this.ISWECHAT && isPhone()) {
            window.open(
              `${url}&token=${token}`,
            );
          } else if (this.isFreeLoginPlatform) {
            service.openLink(`${url}&token=${token}`);
          } else {
            if (!winOpen) {
              throw new Error('浏览器新开弹窗未开启');
            }

            setTimeout(() => {
              winOpen.location = url;
            }, jumpTime);
          }
        } catch (e) {
          Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: [e, '!'],
            displayFooter: false,
            correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
          });
        }
      }
    });
  }

  /**
   * 搜索
   */
  async search (searchParams: any = {}) {
    const params = {
      ...searchParams,
      todoType: this.todoType,
      page: 1,
      batchOperate: this.form.batchOperate,
      appCode: this.form.batchOperate ? this.form.appCode : '',
    };

    this.cTable.pagination.current = 1;
    (this.cTable as any).getTableList(params);
  }

  /**
   * 获取列表数据接口
   */
  async getList (params?: any) {
    const p: any = {
      ...params,
      page: params.page,
    };
    Reflect.deleteProperty(p, 'todoType');
    Reflect.deleteProperty(p, 'batchOperate');
    const res: any = await FlowCenterService.myInitiateWorkflow(p);
    if (res.success) {
      const data: any = res.data || [];

      data.forEach((item: any) => {
        item.instanceStateText = (
          this.flowStatus.find((f: any) => f.enName === item.state) ||
          {}
        ).label;
      });
    }

    this.tableList = res.data;

    return res;
  }

  timeOut (value: string) {
    const oldTime: any = new Date(value);
    const newTime: any = new Date();
    console.log(oldTime, newTime, 'timeOut');
    const timeout: any = parseInt((newTime - oldTime) + '', 10);
    return `已超时${this.formatSeconds(timeout)}`;
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    const unit: number = 10;
    const aSecond: number = 1000;
    const aMinute: number = 60;
    const aDay: number = 24;
    const empty: number = 0;
    let theTime: number = parseInt(value, 10); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    theTime = theTime / aSecond;

    if (theTime > aMinute) {
      theTime1 = parseInt(String(theTime / aMinute), unit);
      theTime = parseInt(String(theTime % aMinute), unit);
      if (theTime1 > aMinute) {
        theTime2 = parseInt(String(theTime1 / aMinute), unit);
        theTime1 = parseInt(String(theTime1 % aMinute), unit);
        if (theTime2 > aDay) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / aDay), unit);
          theTime2 = parseInt(String(theTime2 % aDay), unit);
        }
      }
    }
    let result: string = '';
    if (theTime > empty) {
      result = '' + parseInt(theTime + '', unit) + '秒';
    }
    if (theTime1 > empty) {
      result = '' + parseInt(theTime1 + '', unit) + '分' + result;
    }
    if (theTime2 > empty) {
      result = '' + parseInt(theTime2 + '', unit) + '小时' + result;
    }
    if (theTime3 > empty) {
      result = '' + parseInt(theTime3 + '', unit) + '天' + result;
    }

    return result;
  }

  beforeDestroy () {
    window.removeEventListener('message', () => { });
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  reloadPage (evt: any) {
    if (evt.data === 'reload' && evt.origin === window.location.origin) {
      this.onSearch();
    }
  }

  handleClickRow (record) {
    this.goPage(record);
  }

  /**
   * 获取待办应用列表
   */
  async getTodoApplist () {
    const params: any = {
      batchOperate: true,
      onlineApp: true,
      todoType: 1,
    };

    const res: any = await FlowCenterService.getAppList(params);
    if (res.success) {
      this.appList = res.data || [];
      if (this.appList.length > 0) {
        this.form.appCode = this.appList[0].appCode;
      } else {
        this.form.appCode = '';
      }
    }
  }

  get token () {
    return localStorage.getItem('token');
  }
}
