


















import { Component, Vue, Watch } from 'vue-property-decorator';
import FlowCenterComponent from './flow-center.vue';
import FlowEntrustComponent from './flow-entrust.vue';
import FlowInitiateComponent from './flow-initiate.vue';
import { Menu } from '@cloudpivot7-business-components/pc';

@Component({
  name: 'FlowCenter',
  components: {
    CommonMenu: Menu,
    FlowCenterComponent,
    FlowEntrustComponent,
    FlowInitiateComponent,
  },
})
export default class FlowCenter extends Vue {
  defaultSelected = [] as any;

  collapsed = false;

  menu: any[] = [
    {
      code: 1,
      name: '待办',
      icon: 'h-icon-all-tishinaoling1',
      pathUrl: '',
      group: 1,
    },
    {
      code: 2,
      name: '待阅',
      icon: 'h-icon-all-compiling-applicatio',
      pathUrl: '',
      group: 1,
    },
    {
      code: 3,
      name: '已办',
      icon: 'h-icon-all-Batch-processing-1',
      pathUrl: '',
      group: 1,
    },
    {
      code: 4,
      name: '已阅',
      icon: 'h-icon-all-departmental-sales-p',
      pathUrl: '',
      group: 1,
    },
    {
      code: 5,
      name: '流程委托',
      icon: 'h-icon-all-clues-o',
      pathUrl: '',
      group: 2,
    },
    {
      code: 6,
      name: '我的发起',
      icon: 'h-icon-all-user-modifier-o',
      pathUrl: '',
      group: 3,
    },
  ];

  selectedValue = 1;

  async toggleMenu (menu: any) {
    const data = this.menu.filter((item) => item.code === Number(menu.code));
    this.selectedValue = data ? data.shift().group : 1;
    this.$router
      .replace({
        name: 'flow-center',
        query: {
          todoType: menu.code,
        },
      })
      .catch(() => {});
  }

  toggleCollapsed () {
    this.collapsed = !this.collapsed;
  }

  created () {
    const data = this.menu.filter(
      (item) => item.code === Number(this.$route.query.todoType),
    );
    this.defaultSelected = [Number(this.$route.query.todoType)];
    this.selectedValue = data ? data.shift().group : 1;
  }

  @Watch('$route', { immediate: true, deep: true })
  listenRouter (route) {
    console.log('routeChanged', route);
    this.defaultSelected = [Number(this.$route.query.todoType)];
  }
}
