


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Input } from '@h3/antd-vue';
import Uploader from './upload.vue';

@Component({
  name: 'ApproveModal',
  components: {
    AInput: Input,
    Uploader,
  },
})
export default class ApproveModal extends Vue {
  @Prop({ default: '' }) title!: string;

  approval: string = '';

  resources: any = [];

  defaultUploadProps: any = {
    action: '/api/webapi/file/common/upload',
    directory: false,
    disabled: false,
    icon: {
      type: 'paper-clip',
      color: '#333333',
      size: '18px',
    },
    listType: 'text',
    maxLength: 10,
    maxLengthError: '文件最大可上传10M',
    multiple: true,
    showUploadList: true,
    uploadSize: '10',
    uploadText: '上传附件',
  };

  /**
   * 上传了附件
   */
  onUpload (res: any) {
    if (res && res.status === 'done' && res.response.success) {
      const file = res.response.data;

      if (!file || !file.refId) {
        return;
      }
      const {
        refId, fileExtension, fileSize, name,
      } = file;
      this.resources.push({
        refId,
        fileExtension,
        fileSize,
        name,
      });
    } else if (res && res.status === 'removed' && res.response.success) {
      const file = res.response.data;

      if (!file || !file.refId) {
        return;
      }
      const { refId } = file;
      const removeFile = this.resources.findIndex(
        (f: any) => f.refId === refId,
      );
      this.resources.splice(removeFile, 1);
    }
  }

  submit () {
    const data = {
      content: this.approval,
      resources: this.resources,
    };
    return data;
  }
}
