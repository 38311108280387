// eslint-disable-next-line
import { Rule } from './Form.typings';

export default new class ValidateForm {
  /**
   * 验证入口
   * @param value
   * @param rule
   */
  startValidate (value: any, rule: Rule) {
    if (rule.required) {
      const res: boolean = this.isEmpty(value);
      return { isError: res, message: rule.message };
    }

    if (rule.validator && typeof rule.validator === 'function') {
      const res: boolean = rule.validator(value) as boolean;
      return { isError: !res, message: rule.message };
    }

    if (rule.type && this[rule.type] && typeof this[rule.type] === 'function') {
      const res: boolean = this[rule.type](value, rule);
      return { isError: !res, message: rule.message };
    }

    return { isError: false, message: '' };
  }

  /**
   * 是否是空值
   * @param value
   * @param rule
   */
  private isEmpty (value: any) {
    let isEmpty: boolean = false;

    if (typeof value === 'string' || typeof value === 'undefined' || value === null) isEmpty = !value;

    if (Array.isArray(value)) isEmpty = value.length <= 0;

    if (typeof value === 'number' || typeof value === 'boolean') {
      const numString: string = value.toString();
      isEmpty = !numString;
    }

    return isEmpty;
  }

  /**
   * 字符长度
   * @param value
   * @param rule
   */
  private length (value: string, rule: Rule) {
    if (typeof rule.min === 'undefined' || typeof rule.max === 'undefined') {
      console.warn('Missing min or max');
      return true;
    }

    if (value.length > rule.min && value.length < rule.max) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 是否是纯数值
   * @param value
   * @param rule
   */
  private isNumber (value: string) {
    if (/^[0-9]*$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 是否邮箱地址
   * @param value
   * @param rule
   */
  private isEmail (value: string) {
    // eslint-disable-next-line
    if (/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 是否是手机号码
   * @param value
   * @param rule
   */
  private isPhone (value: string) {
    if ((/^1[3456789]\d{9}$/.test(value))) {
      return true;
    } else {
      return false;
    }
  }
}();
